import React from 'react'
import USGIFMemberImage from '../assets/images/USGIF_Member.jpg'
import V3Seal from '../assets/images/thumbnail_V3_Seal.png'

const Footer = (props) => (
  <footer id="footer">
    {/* <div className="main-wrapper">
            <div className="copyright">
                <li>&copy; GeoYeti 2019-2021</li>
                <li>Design: <a href="https://html5up.net">HTML5 UP</a></li>
            </div>
            <div className="member-of">
                <img id="USGIF-member-image" src={USGIFMemberImage} alt="USGIF Member" />
            </div>
        </div> */}
    <div className="inner out">
      <div className="copyright-wrapper">
        <ul>
          <li>&copy; GeoYeti 2019-{new Date().getFullYear()}</li>,
          <li>
            Design by: <a href="https://html5up.net">HTML5 UP</a>
          </li>
        </ul>
        <div className='logos-container'>
          <img
            className="image left logo-image"
            id="USGIF-member-image"
            src={USGIFMemberImage}
            alt="USGIF Member"
          />
          <img
            className="image left logo-image"
            id="v3-seal-logo"
            src={V3Seal}
            alt="V3 Seal"
          />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
