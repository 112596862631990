import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const Contact = (props) => (
  <div>
    <div className='alert-container'>
    We're proud to announce that GeoYeti has been acquired by Bridge Core as a critical step in building a full-spectrum intelligence company. <a href='https://bcore.com/blog/'>Learn More &gt;&gt;</a>
    </div>
  </div>
)

export default Contact
