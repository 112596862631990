import React from 'react'
import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import CenteredMessageAlert from './CenteredMessageAlert'
import Contact from './Contact'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading',
      isHeaderAlertVisible: true,
    }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 100)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    })
  }

  render() {
    const { children } = this.props

    return (
      <div
        className={`body ${this.state.loading} ${
          this.state.isMenuVisible ? 'is-menu-visible' : ''
        }`}
      >
        <div id="wrapper">
          {/* {this.state.isHeaderAlertVisible && (
          <CenteredMessageAlert
            onClose={() => {
              this.setState({
                isHeaderAlertVisible: !this.state.isHeaderAlertVisible,
              })
            }}
          />
        )} */}
          <Header onToggleMenu={this.handleToggleMenu} />
          {children}
          <Contact />
          <Footer />
          <Menu onToggleMenu={this.handleToggleMenu} />
        </div>
      </div>
    )
  }
}

export default Layout
