import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const Contact = (props) => (
  <section id="contact">
    <div className="inner">
      <section>
        <div className="contact-method">
          <span className="icon alt fa-envelope"></span>
          <p>
            Did not find a current opportunity that aligns with your interests? Send us your resume at <a href="mailto:careers@geoyeti.com">careers@geoyeti.com</a>
          </p>
        </div>
      </section>
      <section>
        <div className="contact-method">
          <span className="icon alt fa-home"></span>
          <h3>We are located in</h3>
          <StaticQuery
            query={graphql`
              query StatesOfOpsQuery {
                markdownRemark(fileAbsolutePath: { regex: "/state/" }) {
                  frontmatter {
                    comp_states_of_ops
                  }
                }
              }
            `}
            render={(data) => (
              <span>{data.markdownRemark.frontmatter.comp_states_of_ops}</span>
            )}
          />
        </div>
      </section>
    </div>
  </section>
)

export default Contact
