import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import CustomAlert from '../components/CustomAlert';

const Header = (props) => (
  <header id="header" className="alt">
    <CustomAlert/>
    <div id='inner-nav'>
      <Link to="/" className="logo">
        <strong>Geoyeti</strong>
      </Link>
      {/* <nav>
        <a className="menu-link" onClick={props.onToggleMenu} href="#">
          Menu
        </a>
      </nav> */}
    </div>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
